import * as React from "react";
import { useExperiment } from "@shared-ui/experiment-context";

export const useLodgingSearchExperiment = (hideFlexibleDates: boolean = false) => {
  if (hideFlexibleDates) {
    return {};
  }

  const { exposure: flexDateContentSearchExposure, logExperiment: logFlexDateContentSearchExposure } = useExperiment(
    "Flex_Date_M2_duration_month_search_HcomBex_web"
  );

  const {
    exposure: flexDateContentSearchExposureVrbo,
    logExperiment: logFlexDateContentSearchExposureVrbo,
  } = useExperiment("Flex_Date_M2_duration_month_v2_Vrbo_web");

  const includeFlexibleCalendarContent = true;

  const includeFlexibleDatesContent =
    flexDateContentSearchExposure.bucket > 0 || flexDateContentSearchExposureVrbo.bucket > 0;

  const onOpenDatesField = React.useCallback(() => {
    try {
      logFlexDateContentSearchExposure(flexDateContentSearchExposure);
    } catch {
      // noop
    }

    try {
      logFlexDateContentSearchExposureVrbo(flexDateContentSearchExposureVrbo);
    } catch {
      // noop
    }
  }, [logFlexDateContentSearchExposure, flexDateContentSearchExposure]);

  return {
    includeFlexibleCalendarContent,
    includeFlexibleDatesContent,
    onOpenDatesField,
  };
};
